<template>
    <div class="main">
        <van-cell-group>
            <van-cell center>
                <template #title>
                    <van-field  required v-model="customObj.gfmc" placeholder="请输入企业名称" label="企业名称"/>
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field required  v-model="customObj.nsrsbh" placeholder="请输入企业税号" label="企业税号"/>
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field   v-model="customObj.gfdz" placeholder="请输入企业地址" label="企业地址"/>
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field   v-model="customObj.gfyh" placeholder="请输入开户行" label="开户行"/>
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field  v-model="customObj.yhzh" placeholder="请输入银行账号" label="开户行账号"/>
                </template>
            </van-cell>
        </van-cell-group>
        <van-cell-group title=" ">
            <van-cell center>
                <template #title>
                    <van-field required  v-model="customObj.gfdh" placeholder="请输入手机号码" label="手机号码"/>
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field  required v-model="customObj.gfyx" placeholder="请输入邮箱地址" label="邮箱地址"/>
                </template>
            </van-cell>
        </van-cell-group>

        <div  style="margin:40px 16px;">
            <van-button type="primary" size="large" @click="saveAction">保 存</van-button>
        </div>

    </div>
</template>

<script>
    import {defineComponent, reactive, getCurrentInstance} from 'vue'
    import customRequest from '@/api/custom.js'
    import { Notify, Dialog} from 'vant';
    import config from '@/config'

    export default defineComponent({
        name: "addCustom",
        setup(){
            const { proxy } = getCurrentInstance();
            const message =  proxy.$root.$route.params.item !== undefined? JSON.parse(proxy.$root.$route.params.item): undefined;
            const obj = message !== undefined? message: {
                id:"",
                nsrsbh: "",
                gfdh: "",
                gfmc: "",
                gfdz: "",
                yhzh: "",
                bz: "",
                gfyh: "",
                gfyx: "",
            };
            const customObj = reactive(obj);
            const saveAction = ()=>{
                if (customObj.nsrsbh.length === 0){
                    Notify({ type: 'warning', message: '请输入企业税号!' });
                    return
                }
                if (customObj.gfmc.length === 0){
                    Notify({ type: 'warning', message: '请输入企业名称!' });
                    return
                }
                // if (customObj.gfdz.length === 0){
                //     Notify({ type: 'warning', message: '请输入企业地址!' });
                //     return
                // }
                // if (customObj.yhzh.length === 0){
                //     Notify({ type: 'warning', message: '请输入开户行账号!' });
                //     return
                // }
                // if (customObj.gfyh.length === 0){
                //     Notify({ type: 'warning', message: '请输入开户行!' });
                //     return
                // }
                if (customObj.gfdh.length === 0){
                    Notify({ type: 'warning', message: '请输入手机号码!' });
                    return
                }
                if (customObj.gfyx.length === 0){
                    Notify({ type: 'warning', message: '请输入邮箱地址!' });
                    return
                }
                if (message !== undefined){
                    customRequest.gf_update(customObj).then(res=>{
                        if (res.returnCode == "00"){
                            Dialog.alert({
                                title: config.commonMes.alertTitle,
                                message: res.returnMessage,
                            }).then(() => {
                                proxy.$root.$router.back(-1);
                            });
                        }
                    })
                } else {
                    customRequest.gf_insert(customObj).then(res=>{
                        if (res.returnCode == "00"){
                            Dialog.alert({
                                title: config.commonMes.alertTitle,
                                message: res.returnMessage,
                            }).then(() => {
                                proxy.$root.$router.back(-1);
                            });
                        }
                    })
                }
            };
            return{
                customObj,
                saveAction
            }
        }
    });
</script>

<style scoped>
    .main{
        background: #eee;
        min-height: 100vh;
    }
</style>